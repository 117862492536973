import List from 'list.js';
import { filterableTable } from './filterable_table';

const tableId = 'published-training-courses-table';

const initTrainingCourseTable = () => {
    const pageData = window.__PAGE_DATA__;

    const trainingCouseActions = (values) => {
        const subscribersLink = values.public_subscribers_url ? `
            <a class="button action_show_url is-link is-light is-small" href="${values.public_subscribers_url}">
                <span class="icon">
                    <i class="fas fa-users"></i>
                </span>
            </a>
        ` : '';

        if (values.is_current_user_subscribed) {
            return `
                <a class="button is-success action_show_url is-small" href="${values.view_subscription_url}">
                    <span class="icon">
                        <i class="fas fa-form"></i>                            
                    </span>
                    ${pageData.lang_view_subscription}
                </a>                    
                ${subscribersLink}
            `;
        } else if (values.can_subscribe) {
            if (values.available_subscriptions > 0) {
                return `
                <a class="button is-primary action_show_url is-small" href="${values.subscribe_url}">
                    <span class="icon">
                        <i class="fas fa-form"></i>                            
                    </span>
                    ${pageData.lang_subscribe}
                </a>              
                ${subscribersLink}      
            `;
            } else {
                return `
                    <span class="tag is-danger">
                        ${pageData.lang_training_course_full_short}
                    </span>
                    ${subscribersLink}
                `;
            }
        } else {
            return `<span class="tag">${pageData.lang_training_course_subscription_closed}</span>${subscribersLink}`;
        }
    };

    const options = {
        valueNames: [
            'title',
            'date',
            'time',
            'type',
            'location',
            'created_at',
            { name: 'subscribe_url', attr: 'href' },
        ],
        item: (values) => `
            <tr>                                
                <td><strong>${values.title}</strong></td>
                <td><span class="tag is-small is-light">${values.date}<span></td>
                <td>
                    <span class="tag is-small is-light">
                        ${values.time} ${values.time_end ? `- ${values.time_end}` : ''}
                    </span>
                </td>
                <td>
                    <span class="tag" style="background-color:${values.type_color};">${values.type}</span>
                </td>
                <td class="location"></td>                                
                <td class="action has-text-centered nowrap">
                    ${trainingCouseActions(values)}
                </td>
            </tr>
        `,
    };

    const list = new List(tableId, options);

    const table = filterableTable(list, '/mycfe/training-courses/list');

    table.addFilter({
        name: 'term',
        className: 'training-course-search',
    });
};

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById(tableId)) {
        initTrainingCourseTable();
    }
});
